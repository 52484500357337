import React from 'react';
import './ErrorModal.css';

const ErrorModal = ({ message, onClose, ErrorResponse }) => {
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-container error" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <span className="close-btn" onClick={onClose}>&#10005;</span>
        </div>
        <div className="modal-body">
          <h2 className="error-title">{message || "Something went wrong!"}</h2>
          {ErrorResponse && (
            <div className="error-response">
              <p className="error-details">{ErrorResponse}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
