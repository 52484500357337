import React from 'react';
import './SuccessModal.css';

const SuccessModal = ({ message, onClose, popupResponse }) => {
  console.log(popupResponse, "from modal");

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-container success" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <span className="close-btn" onClick={onClose}>&#10005;</span>
        </div>
        <div className="modal-body">
          <h2 className="modal-title">{message || "Operation Successful!"}</h2>
          {popupResponse && (
            <div className="popup-response">
              <p>
                <strong>FAID:</strong> <span className="response-value">{popupResponse.faid}</span>
              </p>
              <p>
                <strong>Status of the user:</strong> <span className="response-value">{popupResponse.status}</span>
              </p>
              <p>
                <em>
                  Please note down you unique Flashaid ID (FAID) for future reference
                </em>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
